
.contact-form-wrapper {
    padding: 100px 0;
  }


  
  .contact-form {
    padding: 30px 40px;
    background:var(--body_background);
    border-radius: 12px;
    max-width: 600pxpx;
    margin-top: 4rem;
    box-shadow: 0 4px 10px 0 rgba(222, 219, 219, 0.2), 0 6px 20px 0 rgba(166, 164, 164, 0.19);
  }
  
  .contact-form textarea {
    resize: none;
  }
  
  .contact-form .form-input,
  .form-text-area {
    background-color: #f0f4f5;
    height: 50px;
    padding-left: 16px;
  }
  
  .contact-form .form-text-area {
    background-color: #f0f4f5;
    height: auto;
    /* padding-left: 16px; */
  }
  
  .contact-form .form-control::placeholder {
    color: #aeb4b9;
    font-weight: 500;
    opacity: 1;
  }
  
  .contact-form .form-control:-ms-input-placeholder {
    color: #aeb4b9;
    font-weight: 500;
  }
  
  .contact-form .form-control::-ms-input-placeholder {
    color: #aeb4b9;
    font-weight: 500;
  }
  
  .contact-form .form-control:focus {
    background: #0171b6 !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.07), 0 0 8px #f33fb0;
  }
  
  .contact-form .title {
    text-align: center;
    font-size: 24px;
    font-weight: 500;
  }
  
  .contact-form .description {
    color: #aeb4b9;
    font-size: 14px;
    text-align: center;
  }
  
  .contact-form .submit-button-wrapper {
    text-align: center;
  }
  
  .contact-form .submit-button-wrapper input {
    border: none;
    border-radius: 4px;
    background: rgb(90, 173, 108);
    color: white;
    text-transform: uppercase;
    padding: 10px 60px;
    font-weight: 500;
    letter-spacing: 2px;
  }
  
  .contact-form .submit-button-wrapper input:hover {
    background-color: rgb(0, 0, 255);
  }
  .admins{
    width: 40vw;
    height: auto;
    margin-left: 7rem;
    margin-top: 10rem;
    border-radius: 12px;
    
  }
  .detailss{
    margin-left: 8rem;
  }
  .form{
    background:var(--body_background);
  }
  .for-uss{
    text-align: center;
    font-size: 50px;
    color: rgb(0, 0, 0);
    font-weight: bold;
  }

  /* @media screen and (max-width: 400px) {
   .detailss{
    width: 100vw;
    margin-left: -2rem;
   }
   .admins{
    width: 90vw;
    margin-top: -5rem;
    margin-left: -2rem;
   }
   .for-uss{
    font-size: 30px;
   }

  } */

  @media screen and (max-width: 480px) {
    .detailss{
      width: 100vw;
      margin-left: -2rem;
     }
     .admins{
      width: 90vw;
      margin-top: -5rem;
      margin-left: -2rem;
     }
     .for-uss{
      font-size: 30px;
     }
  }
  /* @media screen and (min-width: 400px) {
    .detailss{
     width: 100vw;
     margin-left: -2rem;
    }
    .admins{
     width: 90vw;
     margin-top: -5rem;
     margin-left: -2rem;
    }
    .for-uss{
     font-size: 30px;
    }
 
   } */