/* General styles for the container */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.fo {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  margin-top: 12rem;
  border-radius: 5px;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.progress-bar {
  width: 100vw ;
  background-color: #f3f3f3;
  border-radius: 5px;
  margin-top: 10px;
  
}

.progress {
  height: 20px;
  background-color: #4caf50;
  border-radius: 5px;

  transition: width 0.4s ease;
}


.fo input[type="text"],
.fo textarea,
.fo input[type="file"],
.fo button {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
}

.fo button {
  cursor: pointer;
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.fo button:hover {
  background-color: #0056b3;
}

.getting {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 5px;
  background-color: #f9f9f9;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 20px;
}

.video-item {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.video-item h3 {
  margin: 0 0 10px 0;
  font-size: 18px;
}

.video-item p {
  margin: 0 0 10px 0;
  font-size: 14px;
}

.video-item video {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.video-item button {
  cursor: pointer;
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  margin-right: 10px;
}

.video-item button:hover {
  background-color: #c82333;
}

/* Responsive styles */
@media (max-width: 768px) {
  .fo, .getting {
      padding: 10px;
  }

  .fo input[type="text"],
  .fo textarea,
  .fo input[type="file"],
  .fo button {
      font-size: 14px;
  }

  .video-item h3 {
      font-size: 16px;
  }

  .video-item p {
      font-size: 12px;
  }
}

@media (max-width: 480px) {
  .fo, .getting {
      padding: 5px;
  }

  .fo input[type="text"],
  .fo textarea,
  .fo input[type="file"],
  .fo button {
      font-size: 12px;
  }

  .video-item h3 {
      font-size: 14px;
  }

  .video-item p {
      font-size: 12px;
  }
}
