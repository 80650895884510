/* userProfile.css */

.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 12rem;
}

.user-profile {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
}

.user-profile h2 {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
}

.user-details {
    margin-bottom: 20px;
}

.user-details p {
    font-size: 18px;
    margin-bottom: 10px;
}

.profile-image {
    text-align: center;
}

.profile-image h3 {
    font-size: 20px;
    margin-bottom: 10px;
}

.profile-image img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
}

@media screen and (max-width: 600px) {
    .user-profile {
        padding: 10px;
    }

    .user-profile h2 {
        font-size: 20px;
    }

    .user-details p {
        font-size: 16px;
    }
}