div.gallery {
  border: 1px solid #ccc;
  border-radius: 12px;


}

.foot {
  background: var(--body_background);
  margin-top: -7rem;
  margin-bottom: -2rem;
  
}

.all {
  margin-top: 12rem;
}


div.gallery:hover {
  border: 1px solid #777;
}

div.gallery img {
  width: 100%;
  height: 50vh;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

div.desc {
  padding: 15px;
  text-align: center;
}

* {
  box-sizing: border-box;
}

.containers {
  display: grid;
  margin-left: 2rem;
  border-radius: 12px;
  height: auto;

  margin-right: 2rem;
  margin-bottom: 2rem;

  grid-template-columns: repeat(4, minmax(250px, 1fr));
}

.responsivee {
  /* padding: 0 6px; */
  /* float: left; */
  background: var(--body_background);
  border-radius: 12px;
  width: 22vw;
  margin-left: 1rem;


  margin-top: 3rem;
  transition: 0.4s ease-in-out;

  box-shadow: 3px 10px 10px 7px rgb(216, 210, 210);


}

.responsivee:hover {
  box-shadow: 0px 0px 0px 0px rgb(143, 141, 141);
}

.staff-name {
  margin-left: 3rem;
  font-weight: bold;
  font-size: 50px;
}

#mains {
  width: 23vw;
  margin-left: 3.5rem;
}

.namess {
  font-size: 19px;
  margin-right: 3rem;
  font-family: "Protest Riot", sans-serif;

}

.subjects {
  font-size: 19px;
  font-style: italic;
  margin-top: -2.3rem;
  text-align: center;
}

#book {
  margin-right: 17rem;
  color: blue;
}

#icons {
  margin-right: 18rem;
  margin-bottom: -2.2rem;
  color: blue;
}

#iconss {
  margin-right: 18rem;
  margin-bottom: 10rem;
}






.grid-container {
  display: grid;
  margin-left: 2rem;
  border-radius: 12px;
  height: auto;
  grid-gap: 20px;

  margin-right: 2rem;
  margin-bottom: 2rem;

  grid-template-columns: repeat(4, minmax(700px, 1fr));
}

.grid-item {
  border: 1px solid #ccc;
  padding: 20px;
}

@media screen and (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
 

}






@media screen and (max-width: 968px) {
  .grid-container {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }



}







@media only screen and (max-width: 700px) {
  .responsivee {
    width: 49.99999%;
    margin: 6px 0;
  }


  .containers {


    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

  }
  .hq{
    margin-left: 5rem;
  }

}

@media only screen and (max-width: 969px) {
  .responsivee {
    width: 80vw;
    margin: 6px 0;
  }


  .containers {
    grid-row: span 3;
    grid-column: span 3;

    /* display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); */

    display: grid;
    margin-left: 2rem;
    border-radius: 12px;
    height: auto;

    margin-right: 2rem;
    margin-bottom: 2rem;

    grid-template-columns: repeat(1, minmax(250px, 1fr));
  }
}

@media only screen and (max-width: 500px) {
  .staff-name {
    margin-left: 1rem;
    font-size: 40px;
  }

  .responsivee {
    width: 95vw;
    margin-left: -1.5rem;
  }
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}