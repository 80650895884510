/* General styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.line {
  margin: 20px 0;
}

.hq {
  text-align: center;
  font-size: 2rem;
  margin: 20px 0;
}

.col-preginations-image {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
}

.row-preginations-image {
  flex: 1 1 50%;
  padding: 10px;
}

#forms {
  display: flex;
  flex-direction: column;
  max-width: 700px;
  margin: 0 auto;
}

#forms label {
  margin-bottom: 15px;
}

#forms label h6 {
  margin-bottom: 5px;
  font-size: 1rem;
  color: #333;
}

#forms input,
#forms select,
#forms textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

#forms button {
  padding: 10px 20px;
  background-color: #2fa944;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}

#forms button:hover {
  background-color: #227d2f;
}

.images-adminis {
  text-align: center;
}

.images-adminis img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .col-preginations-image {
      flex-direction: column;
      padding: 10px;
  }

  .row-preginations-image {
      flex: 1 1 100%;
      padding: 10px;
  }

  .hq {
      font-size: 1.5rem;
  }

  #forms {
      max-width: 100%;
  }
}

@media (max-width: 480px) {
  #forms label h6 {
      font-size: 0.9rem;
  }

  #forms input,
  #forms select,
  #forms textarea {
      font-size: 0.9rem;
  }

  #forms button {
      font-size: 0.9rem;
  }

  .hq {
      font-size: 1.2rem;
  }
}
