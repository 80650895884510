#app {
  height: 100%;
}

/* html,
  body {
    position: relative;
    height: 100%;
  }
  
  body {
    background: #eee;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
  } */

.swiper {
  width: 100%;
  height: 100%;
  
}


.swiper-slide {
  margin-top: 12rem;
  height: 100vh;
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.autoplay-progress {
  position: absolute;
  right: 16px;
  bottom: 16px;
  z-index: 10;
  width: 48px;
  height: 48px;

  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color:white;
  background: rgb(90,173,108);
 
  border-radius: 12px;
}

.autoplay-progress svg {
  --progress: 0;
  position: absolute;
  left: 0;
  top: 0px;

  z-index: 10;
  width: 100%;
  height: 100%;
  stroke-width: 4px;
  stroke: var(--swiper-theme-color);
  fill: none;
  stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
  stroke-dasharray: 125.6;
  transform: rotate(-90deg);
}

.swiper-button-prev {

  color:rgb(90,173,108);
}

.swiper-button-next {
  color: rgb(90,173,108);
}

.swiper-pagination-bullet {
  background: rgb(90,173,108);
}


@media screen and (max-width: 700px) {
  .swiper-slide img {
   
    width: 100%;
    height: 100%;
    
  }
  .swiper-slide {
    height: 50vh;
  
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
 
}