:root {
  --body_background:white;
  --body_color: black;
  --Link_color: rgb(0, 0, 0);
  
}

[data-theme="dark"]{
  --body_background:#282c34;
  --body_color: rgb(255, 255, 255);
  --Link_color: rgb(255, 255, 255);
  --h3_color:white;

}
[data-theme="light"]{
  --body_background:white;
  --body_color: black;
  --Link_color: rgb(0, 0, 0);
  --h3_color:white;

}


body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */

    font-family: "Playfair Display", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* -webkit-user-select: none;
  -ms-user-select: none; 
  user-select: none; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html{
  scroll-behavior: smooth;
}