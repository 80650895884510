/* src/videos.css */
.main_div {
    margin-top: 8rem;
    display: grid;
    grid-template-columns: repeat(4, minmax(250px, 1fr));
    gap: 16px;
    padding: 16px;

}

.video-item {

    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    padding: 16px;
}

.video-item h3 {
    margin: 8px 0;
    font-size: 16px;
    color: #333;
}

.video-item video {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

@media (max-width: 768px) {
    .main_div {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
}

@media (max-width: 480px) {
    .main_div {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
}