.images-chandan {
    height: auto;
    width: 40vw;
   
    margin-left: 25rem;
}
#main-sybmols{
    margin-top: -7rem;
    margin-bottom: -1rem;
}
.mains {
    width: 93vw;
    height: auto;
    margin-left: 3rem;
    margin-right: 2rem;

    background: var(--body_background);
}

.form-control {
    width: 20vw;
    border-radius: 12px;
    margin-left: 4rem;
}

.lables {
    margin-left: 5rem;
}

#formss {

    margin-top: 1rem;
    height: 12vh;
    margin-left: 5%;

}

#control {
    width: 20vw;
    margin-left: 4rem;
    border-radius: 12px;
    margin-top: 8rem;
}

.select-class {
    margin-top: rem;

}

.mains-mains {
    margin-top: 6rem;
    margin-left: 15rem;

}

.selections {
  
    margin-left: 6rem;
}

.ids {
    border-radius: 12px;
    width: 10vw;
    height: 5vh;
}

@media screen and (max-width: 400px) {



    .images-chandan {
        height: auto;
        width: 90vw;
        margin-top: 3rem;
        margin-left: -1.4rem;
        border-radius: 12px;
    }

    #control {
        width: 75vw;
        margin-left: 1.2rem;
        border-radius: 12px;
        margin-top: 8rem;
    }

    .ids {
        border-radius: 12px;
        width: 90vw;
        height: 5vh;
    }

    .selections {
        margin-top: 1rem;
        margin-left: 1rem;
        width: 20vw;
    }

    .mains-mains {
        margin-top: 8rem;
        margin-left: 0rem;
    }

    .mains {
        margin-left: 1rem;
        height: auto;
    }

    .form-control {
        width: 77vw;
        border-radius: 12px;
        margin-left: 1rem;
    }


}