@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");


.navbar {
   /* background: #ffffff; */
   background: linear-gradient(120deg, rgb(90,173,108)20%, transparent 60%), var(--body_background);
   box-shadow: 4px 4px 3px 0px rgb(90, 173, 108);
  

}

/* #circle{

  margin-left: 2rem;
  
  margin-top: 1rem;
  } */

#moon {
   color: var(--body_color);
}

#sun {
   color: var(--body_color);
}



.images-circle {
   display: inline-block;

   height: auto;
   width: 70px;
   vertical-align: top;
   border: 0;
   -webkit-border-radius: 50%;
   -moz-border-radius: 50%;
   border-radius: 50%;
}

.images-circle img {}

.navbar-expand-lg .navbar-nav .nav-link {
   color: var(--body_color);
   font-size: 16px;
   font-size: bold;
}


/* Add this to your existing CSS file */
.navbar-nav .nav-item.dropdown:hover .dropdown-menu {
   display: block;
}

.navbar-dark {
   padding: 0px 0;
}

.dropdown-menu {
   display: none;
   position: absolute;
   z-index: 1000;
   background-color: rgb(90, 173, 108);

   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   border-radius: 5px;
   width: 22rem;
}

.dropdown-menu a {
   color: #ffffff;

   display: block;
   text-decoration: none;
}

.dropdown-menu a:hover {
   border-radius: 12px;
   color: rgb(6, 6, 6);

}

/* Add this to your existing CSS file */
.dropdown-menu {
   /* Your existing styles */
}

/* Icon style when not hovered */


#darkmode {
   color: #000;
}

#lightmode {
   color: rgb(0, 0, 0);
}

/* Icon style when hovered */
.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show>.nav-link {
   color: var(--body_color);
}




.navbar-dark .navbar-nav .active>.nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show>.nav-link {

   color: rgb(90, 173, 108);
}

.navbar-dark .navbar-toggler {

   border: none;
}

.navbar-toggler-icon {
   display: inline-block;
   width: 1.5em;
   height: 1.5em;
   vertical-align: middle;

   content: "";
   background: no-repeat center center;
   background-size: 100% 100%;

}

.navbar-dark .navbar-toggler-icon {
   background-image: url(https://cdn-icons-png.flaticon.com/512/6711/6711395.png);
   width: 4rem;
   height: 4rem;


}


.blinking {
   animation: blinker 1.5s linear infinite;
   font-weight: bold;
   text-transform: uppercase;

}

@keyframes blinker {
   50% {
      opacity: 0;
   }
}

.badge {
   animation: blinker 1.5s linear infinite;
   background: rgb(90, 173, 108);

}

@keyframes blinker {
   50% {
      opacity: 0;
   }
}



.btn-close {
   --bs-btn-close-color: #ffffff;
   --bs-btn-close-bg: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e);
   --bs-btn-close-opacity: 0.5;
   --bs-btn-close-hover-opacity: 0.75;
   --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
   --bs-btn-close-focus-opacity: 1;
   --bs-btn-close-disabled-opacity: 0.25;
   --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
   box-sizing: content-box;
   width: 3em;
   height: 2em;
   padding: .30em .30em;
   color: var(--bs-btn-close-color);
   background: transparent var(--bs-btn-close-bg) center / 1em auto no-repeat;
   border: 0;
   border-radius: .375rem;
   opacity: var(--bs-btn-close-opacity);
}











* {
   box-sizing: border-box;
}





.checkbox {
   opacity: 0;
   position: absolute;
}

.checkbox-label {
   background-color: rgb(90, 173, 108);
   width: 50px;
   height: 26px;
   border-radius: 50px;
   position: relative;
   padding: 5px;
   cursor: pointer;
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.fa-moon {
   color: #f1c40f;
}

.fa-sun {
   color: #f39c12;
}

.checkbox-label .ball {
   background-color: rgb(220, 225, 222);
   width: 22px;
   height: 22px;
   position: absolute;
   left: 2px;
   top: 2px;
   border-radius: 50%;
   transition: transform 0.2s linear;
}

.checkbox:checked+.checkbox-label .ball {
   transform: translateX(24px);
}

















/*  Support me if you like it */


@media (max-width:441px) {
   .sidebar {
      background: white;
   }

   .navbar-dark .navbar-nav .active>.nav-link,
   .navbar-dark .navbar-nav .nav-link.active,
   .navbar-dark .navbar-nav .nav-link.show,
   .navbar-dark .navbar-nav .show>.nav-link {
      color: #ffffff;
   }

   .navbar-expand-lg .navbar-nav .nav-link {
      color: white;
      font-size: 16px;
      font-size: bold;
   }


   .navbar {
      /* background: #ffffff; */
      background: linear-gradient(115deg, rgb(90, 173, 108)30%, transparent 20%), var(--body_background);
      box-shadow: 4px 4px 3px 0px rgb(90, 173, 108);

   }

   #darkmode {
      color: white;

   }

   #lightmode {
      color: white;
   }


}

@media (max-width:969px) {
   .sidebar {
      background: white;
   }

   .navbar-dark .navbar-nav .active>.nav-link,
   .navbar-dark .navbar-nav .nav-link.active,
   .navbar-dark .navbar-nav .nav-link.show,
   .navbar-dark .navbar-nav .show>.nav-link {
      color: #ffffff;
   }

   .navbar-expand-lg .navbar-nav .nav-link {
      color: white;
      font-size: 16px;
      font-size: bold;
   }

   .navbar {
      /* background: #ffffff; */
      background: linear-gradient(115deg, rgb(90, 173, 108)30%, transparent 20%), var(--body_background);
      box-shadow: 4px 4px 3px 0px rgb(90, 173, 108);
   }

   #darkmode {
      color: white;

   }

   #lightmode {
      color: white;
   }


}