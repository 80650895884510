.div-box {}

.downlaod {
    margin-top: 14rem;
    margin-left: 3rem;
    font-weight: bold;
    font-size: 50px;
    text-transform: uppercase;
}

#btns {
    width: 10vw;
    margin-left: 80%;
    margin-top: -3rem;
    background: rgb(90,173,108);
}

#nmsc {
    margin-top: 2rem;
    margin-left: 2rem;
    font-weight: bold;
}
#nms{
    display: flex;
    
}

.main-content {
    background: #f3f0f0;
    margin-left: 3rem;
    margin-right: 3rem;
}


.main-books {
    margin-top: 5rem;
    background: rgb(223, 217, 217);
    margin-left: 2rem;
    margin-right: 2rem;
    height: auto;
    margin-bottom: 5rem;
}

@media screen and (max-width: 700px) {

    .downlaod {
        margin-top: 14rem;
        margin-left: 2rem;
        font-weight: bold;
        font-size: 25px;
    }
    .main-books {
        margin-top: 3rem;
        background: rgb(223, 217, 217);
        margin-left: 1rem;
        margin-right: 1rem;
        height: 150vw;
        margin-bottom: 5rem;
    }
    .main-content {
        background: #f3f0f0;
        margin-left: 1rem;
        margin-right: 1rem;
    }
    #btns{
        flex-direction: column;
        margin-top: 2rem;
        margin-left: 2rem;
        width: 55vw;
    }
}