@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap');


.card {
    background: white;
    padding: 16px;
    border-radius: 16px;
    box-shadow: 0px 30px 100px 0px rgba(17, 23, 41, 0.1);
    width: min-content;
    margin: 0;
}
.logine{
    font-weight: bold;
    margin-left: 5rem;
    margin-top: 10rem;
    text-align: center;
}
.card .img-container {
    width: 20vw;
    height: auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    border-radius: 8px;
}

.card .tags {
    width: 336px;
    overflow: hidden;
    position: relative;
}

.card ul {
    list-style: none;
    padding: 0;
    margin: 16px 0;
    width: fit-content;
    display: flex;
    gap: 0.5em;
}

.card .tags::after {
    height: 100%;
    width: 25%;
    background-image: linear-gradient(-90deg, white 50%, transparent);
    content: '';
    position: absolute;
    right: -38px;
    top: 0;
    z-index: 5;
}

.card ul::-webkit-scrollbar {
    display: none;
}

.card ul li {
    padding: 4px 16px;
    background-color: rgba(136, 58, 225, 0.25);
    border-radius: 16px;
    width: fit-content;
    color: #883ae1;
    font-size: 0.625rem;
    font-weight: 700;
}

.card h1 {
    font-size: 1.125rem;
    margin-bottom: 6px;
    font-weight: 700;
    color: #20293A;
}

.card p {
    font-size: 0.875rem;
    font-weight: 300;
    margin: 0;
    line-height: 1.5em;
    color: #6C727F;
    position: relative;
}

.card p:last-child {
    margin-top: 20px;
    padding-top: 20px;
    font-size: 0.75rem;
    font-weight: 400;
}

.card p:last-child::before {
    content: '';
    width: calc(100% + 32px);
    height: 1px;
    background: black;
    position: absolute;
    top: -1px;
    left: -16px;
    opacity: 0.1;
}

.card hr {
    opacity: 0.1;
    border-color: black;
    position: absolute;
}



.massage-grid {
    display: grid;
    margin-left: 6rem;
    border-radius: 12px;
    height: auto;
    margin-top: 12rem;
    gap: 10px 1rem;
  
    margin-right: 2rem;
    margin-bottom: 2rem;
  
    grid-template-columns: repeat(3, minmax(250px, 1fr));
}

.card {
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
}

.img-container img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
}

.card h1 {
    font-size: 1.5rem;
    margin-top: 10px;
}

.card p {
    font-size: 1rem;
    margin: 5px 0;
}

@media screen and (max-width: 768px) {
    .image{
        width: 85vw;
        height: auto;
    }
    .massage-grid {
        margin-left: 1rem;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
    .card .img-container {
        width: 80vw;
        height: auto;
        overflow: hidden;
        display: flex;
        align-items: center;
        border-radius: 8px;
    }
    .card{
        margin-left: -1rem;
    }
}