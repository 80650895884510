body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
}

header {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 1rem;
    margin-top: 5rem;
}

.mm {
    width: 20vw;
    height: auto;
    border-radius: 50%;
}

navs {
    background-color: #444;
    text-align: center;
}

navs uls {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

navs uls li {
    display: inline;
    margin: 10px;
}

navs uls li a {
    text-decoration: none;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
}



/* Add more styling as needed */

@media only screen and (max-width: 600px) {
    /* Add responsive styles here */
}
